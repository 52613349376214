import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title
);

const UnplacedStudentsHistogram = () => {
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const cgpaRanges = [];
    for (let i = 1; i <= 10; i += 0.5) {
      cgpaRanges.push(`${i.toFixed(1)}-${(i + 0.49).toFixed(2)}`);
    }

    const unplacedPercentages = [
      95, 90, 85, 80, 75, 65, 55, 45, 35, 25, 20, 15, 10, 8, 5, 3, 2, 1, 0.5,
    ];

    setData({
      labels: cgpaRanges,
      datasets: [
        {
          label: 'Percentage of Unplaced Students',
          data: unplacedPercentages,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    });
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Percentage of Unplaced Students by CGPA Range',
        font: {
          size: 16,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `Unplaced: ${context.raw}%`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Percentage of Unplaced Students (%)',
          font: {
            weight: 'bold',
          },
        },
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
      x: {
        title: {
          display: true,
          text: 'CGPA Range',
          font: {
            weight: 'bold',
          },
        },
      },
    },
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow p-6">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default UnplacedStudentsHistogram;
