import React from 'react';
import { Pie } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';

function PieChart({ props }) {
  const history = useHistory(); // Initialize history
  // Function to handle the click event
  const handleClick = (event, elements) => {
    if (elements.length > 0) {
      const elementIndex = elements[0].index; // Get the clicked slice index
      const branchName = props.labels[elementIndex]; // Get the branch name from labels

      // Navigate to the branch details page
      history.push(`/branch-details/${branchName}`);
    }
  };

  return (
    <div>
      <Pie
        data={props}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  // Extract the branch name from the data labels and the count of students
                  const branchName = context.label; // This assumes 'label' is the branch name
                  const studentCount = context.raw; // This is the student count

                  // Return the custom tooltip content
                  return `${branchName}: ${studentCount} students`;
                },
              },
            },
          },
          onClick: handleClick,
        }}
      />
    </div>
  );
}

export default PieChart;
