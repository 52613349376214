import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import instance from '../../api/axios';
import './Statistics.css';
import { Container, Paper } from '@material-ui/core';
import styles from '../../styles/pages/Programs.module.css';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PieChart from './PieChart';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import UnplacedStudentsHistogram from '../../components/Statistics/UnplacedStudentsHistogram';

const drawerWidth = 240;

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ArcElement
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: '#f0f0f0',
    color: '#000',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));
function Statistics() {
  const [piechartDataBTech, setPieChartDataBTech] = useState(null);
  const [piechartDataMTech, setPieChartDataMTech] = useState(null);
  const [piechartDataMsc, setPieChartDataMsc] = useState(null);
  const [piechartDataMT, setPieChartDataMT] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    instance.get('placement_statistics/branch_ctc/').then((res) => {
      const data = res.data;

      // Extract branch names and student
      let filter = data.filter((item) => item.program === 'BTech');
      const branches = filter.map((item) => item.branch);
      const studentCounts = filter.map((item) => item.student_count);
      // Set up the chart data
      setPieChartDataBTech({
        labels: branches, // Branch names
        datasets: [
          {
            label: 'Number of Students Placed',
            data: studentCounts, // Student count data
            backgroundColor: [
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
          },
        ],
      });

      filter = data.filter((item) => item.program === 'Mtech');
      const Mtechbranches = filter.map((item) => item.branch);
      const MtechstudentCounts = filter.map((item) => item.student_count);

      setPieChartDataMTech({
        labels: Mtechbranches, // Branch names
        datasets: [
          {
            label: 'Number of Students Placed',
            data: MtechstudentCounts, // Student count data
            backgroundColor: [
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
          },
        ],
      });

      filter = data.filter((item) => item.program === 'Msc');
      const Mscbranches = filter.map((item) => item.branch);
      const MscstudentCounts = filter.map((item) => item.student_count);

      setPieChartDataMsc({
        labels: Mscbranches, // Branch names
        datasets: [
          {
            label: 'Number of Students Placed',
            data: MscstudentCounts, // Student count data
            backgroundColor: [
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
          },
        ],
      });

      filter = data.filter((item) => item.program === 'Msc-Mtech');
      const MTbranches = filter.map((item) => item.branch);
      const MTtudentCounts = filter.map((item) => item.student_count);

      setPieChartDataMT({
        labels: MTbranches, // Branch names
        datasets: [
          {
            label: 'Number of Students Placed',
            data: MTtudentCounts, // Student count data
            backgroundColor: [
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
          },
        ],
      });
    });
  }, []);

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          <Container maxWidth="lg">
            <div className="main">
              <Paper
                className={styles.heading}
                style={{ background: '#012970', color: '#fff' }}
                elevation={2}
              >
                <EqualizerIcon
                  fontSize="large"
                  style={{ margin: '0 1.2rem', padding: '0' }}
                />
                Statistics
              </Paper>
              <div className={classes.graphContainer}>
                <UnplacedStudentsHistogram />
              </div>
              <div className="piechart">
                *click on individiual pie for more details
                <div className="sector">
                  <h1 style={{ margin: 'auto' }}>
                    Student Placement by Branch
                  </h1>
                  <h2 style={{ margin: 'auto' }}>BTech</h2>
                  {piechartDataBTech ? (
                    <div
                      style={{ width: '50%', height: '50%', margin: 'auto' }}
                    >
                      <PieChart props={piechartDataBTech} />
                    </div>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
                <div className="sector">
                  <h2 style={{ margin: 'auto' }}>MTech</h2>
                  {piechartDataMTech ? (
                    <div
                      style={{ width: '60%', height: '60%', margin: 'auto' }}
                    >
                      <PieChart props={piechartDataMTech} />
                    </div>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
                <div className="sector">
                  <h2 style={{ margin: 'auto' }}>MSc</h2>
                  {piechartDataMsc ? (
                    <div
                      style={{ width: '50%', height: '50%', margin: 'auto' }}
                    >
                      <PieChart props={piechartDataMsc} />
                    </div>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
                <div className="sector">
                  <h2 style={{ margin: 'auto' }}>MSc-Mtech</h2>
                  {piechartDataMT ? (
                    <div
                      style={{ width: '50%', height: '50%', margin: 'auto' }}
                    >
                      <PieChart props={piechartDataMT} />
                    </div>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </main>
      </div>
    </>
  );
}

export default Statistics;
