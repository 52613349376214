import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Paper } from '@material-ui/core';
import styles from '../../styles/pages/Programs.module.css';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import instance from '../../api/axios';
import './Statistics.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

function BranchDetails() {
  const { branchName } = useParams(); // Get the branch name from the URL
  const [meanctc, setMeanctc] = useState(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    instance
      .get('placement_statistics/branch_mean_ctc/')
      .then((res) => {
        const data = res.data;
        const branchData = data.find((item) => item.branch === branchName);
        setMeanctc(branchData.mean_ctc.slice(0, 5));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        // Optionally set an error state here
      });

    instance.get('placement_statistics/branch_ctc/').then((res) => {
      const data = res.data;

      // Filter the data to get details of the current branch
      const branchData = data.find((item) => item.branch === branchName);

      if (branchData) {
        // Process CTC data
        const ctcValues = branchData.ctc_list
          .split(',')
          .map((ctc) => parseFloat(ctc))
          .filter((ctc) => !isNaN(ctc)); // Parse CTC values to float and filter out "None"

        // Count students in CTC ranges
        const ctcRanges = [];
        const rangeLabels = [];

        for (let i = 0; i <= Math.max(...ctcValues) / 5; i++) {
          const rangeStart = i * 5;
          const rangeEnd = (i + 1) * 5;
          const label = `${rangeStart}-${rangeEnd}`;
          rangeLabels.push(label);

          const count = ctcValues.filter(
            (ctc) => ctc >= rangeStart && ctc < rangeEnd
          ).length;
          ctcRanges.push(count);
        }

        // Set up the chart data
        setChartData({
          labels: rangeLabels,
          datasets: [
            {
              label: 'Number of Students',
              data: ctcRanges,
              backgroundColor: 'rgba(75, 192, 192, 0.6)', // Bar color
              borderColor: 'rgba(75, 192, 192, 1)', // Border color
              borderWidth: 1,
            },
          ],
        });
      }
    });
  }, [branchName]);

  return (
    <Container maxWidth="lg">
      <Paper
        className={styles.heading}
        style={{ background: '#012970', color: '#fff' }}
        elevation={2}
      >
        <EqualizerIcon
          fontSize="large"
          style={{ margin: '0 1.2rem', padding: '0' }}
        />
        Branch Details: {branchName}
      </Paper>
      <h1 style={{ width: '80%', margin: 'auto' }} className="">
        Mean Ctc of {branchName} is <p>{meanctc}</p>
      </h1>

      <div
        style={{ width: '70%', margin: 'auto' }}
        className="branch-details sector"
      >
        <p style={{ margin: 'auto' }}>
          {branchName} Branch Placement CTC Distribution
        </p>
        {chartData ? (
          <Bar
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      return `${context.raw} students in this range`;
                    },
                  },
                },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'CTC Range (in lakhs)',
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: 'Number of Students',
                  },
                  beginAtZero: true,
                },
              },
            }}
          />
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </Container>
  );
}

export default BranchDetails;
