import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import instance from '../../api/axios';
import { Container, CssBaseline, makeStyles, Paper } from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import styles from '../../styles/pages/Programs.module.css';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: '#f0f0f0',
    color: '#000',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const CompaniesBySector = () => {
  const [companies, setCompanies] = useState([]);
  const { sectorName } = useParams();
  const classes = useStyles();
  const [companyDetails, setCompanyDetails] = useState({});
  //   console.log('sectorname= ', sectorName);

  useEffect(() => {
    instance
      .get('/placement_statistics/companies_by_sector/')
      .then((response) => {
        const data = response.data;
        const sectorData = data.find((item) => item.sector === sectorName);
        setCompanies(sectorData ? sectorData.companies : []);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    instance
      .get('/placement_statistics/companies_ctc/')
      .then((response) => {
        const detailsData = response.data;
        const detailsMap = {};
        detailsData.forEach((company) => {
          detailsMap[company.company] = company;
        });
        setCompanyDetails(detailsMap);
        console.log(detailsMap);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Container
          maxWidth="lg"
          style={{ marginTop: '60px' }}
          className={classes.container}
        >
          <div className="main">
            <Paper
              className={styles.heading}
              style={{ background: '#012970', color: '#fff', marginTop: '0px' }}
              elevation={2}
            >
              <EqualizerIcon
                fontSize="large"
                style={{ margin: '0 1.2rem', padding: '0' }}
              />
              Company Statistics
            </Paper>
            <div style={{ padding: '16px' }}>
              <h1
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  marginBottom: '16px',
                }}
              >
                Companies in {sectorName}
              </h1>
              {companies.length > 0 ? (
                <ul style={{ listStyleType: 'disc', paddingLeft: '24px' }}>
                  {companies.map((company, index) => (
                    <li key={index} style={{ marginBottom: '16px' }}>
                      <strong>{company}</strong>
                      {companyDetails[company] ? (
                        <ul
                          style={{ marginLeft: '16px', listStyleType: 'disc' }}
                        >
                          <li>
                            Average CTC: {companyDetails[company].avg_ctc} LPA
                          </li>
                          <li>
                            Average Base: {companyDetails[company].avg_base} LPA
                          </li>
                          <li>
                            Highest CTC: {companyDetails[company].highest_ctc}{' '}
                            LPA
                          </li>
                          <li>
                            Lowest CTC: {companyDetails[company].lowest_ctc} LPA
                          </li>
                          <li>Offers: {companyDetails[company].offers}</li>
                          <li>
                            Male: {companyDetails[company].gender_stats.male}{' '}
                            &#32;&#32; Female:{' '}
                            {companyDetails[company].gender_stats.female}
                          </li>
                        </ul>
                      ) : (
                        <p style={{ marginLeft: '16px', color: '#6b7280' }}>
                          Details not available
                        </p>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No companies found for this sector.</p>
              )}
            </div>
          </div>
        </Container>
      </main>
    </div>
  );
};

export default CompaniesBySector;
