import { Container, CssBaseline, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import instance from '../../api/axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import styles from '../../styles/pages/Programs.module.css';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import './Statistics.css';
import { Pie } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import CompanyStats2 from './CompanyStats2';

const drawerWidth = 240;
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ArcElement
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: '#f0f0f0',
    color: '#000',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

function CompanyStats() {
  const history = useHistory();
  const classes = useStyles();
  const [chartData, setChartData] = useState(null);
  const [groupedByPhase, setGroupedByPhase] = useState({});
  const [phaseData, setPhaseData] = useState({});
  const [filteredPhase, setFilteredPhase] = useState(null);
  const [idx, setIdx] = useState(0);
  useEffect(() => {
    instance
      .get('placement_statistics/company_details/?expand=true/')
      .then((response) => {
        const data = response.data;

        // Create an object with phase as keys and lists of companies
        const phaseGroups = {};

        data.forEach(({ company, phases }) => {
          phases.forEach(({ phase }) => {
            const phaseNumber = parseInt(phase.split('-')[1], 10);
            if (!phaseGroups[phaseNumber]) {
              phaseGroups[phaseNumber] = [];
            }
            phaseGroups[phaseNumber].push(company);
          });
        });

        setGroupedByPhase(phaseGroups);
        // Prepare data for the pie chart
        const pieData = {
          labels: Object.keys(phaseGroups).map((phase) => `Phase ${phase}`),
          datasets: [
            {
              data: Object.values(phaseGroups).map(
                (companies) => companies.length
              ),
              backgroundColor: [
                'rgba(255, 99, 132, 0.6)',
                'rgba(54, 162, 235, 0.6)',
                'rgba(255, 206, 86, 0.6)',
                'rgba(75, 192, 192, 0.6)',
              ],
            },
          ],
        };

        setPhaseData(pieData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Handle filtering by phase
  const handleFilter = (phase, index) => {
    setFilteredPhase(phase);
    setIdx(index);
  };

  useEffect(() => {
    instance.get('placement_statistics/companies_by_sector/').then((res) => {
      const data = res.data;
      const filteredData = data.filter((item) => item.sector !== null);

      // Process the data for Chart.js
      const labels = filteredData.map((item) => item.sector); // Sectors
      const companyCounts = filteredData.map((item) => item.companies.length); // Number of companies in each sector

      // Set up the chart data
      setChartData({
        labels: labels,
        datasets: [
          {
            label: 'Number of Companies',
            data: companyCounts,
            backgroundColor: 'rgba(75, 192, 192, 0.6)', // Bar color
            borderColor: 'rgba(75, 192, 192, 1)', // Border color
            borderWidth: 1,
          },
        ],
      });
    });
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Container
          maxWidth="lg"
          style={{ marginTop: '60px' }}
          className={classes.container}
        >
          <div className="main">
            <Paper
              className={styles.heading}
              style={{ background: '#012970', color: '#fff', marginTop: '0px' }}
              elevation={2}
            >
              <EqualizerIcon
                fontSize="large"
                style={{ margin: '0 1.2rem', padding: '0' }}
              />
              Company Statistics
            </Paper>
            <div className="sector">
              <h1 style={{ margin: 'auto' }}>
                {' '}
                Company Distribution by Sector
              </h1>
              {chartData ? (
                <Bar
                  data={chartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            return `${context.raw} companies`;
                          },
                        },
                      },
                    },
                    onClick: (event, elements) => {
                      if (elements.length > 0) {
                        const clickedIndex = elements[0].index;
                        const clickedSector = chartData.labels[clickedIndex];
                        // Navigate to a new page with the clicked sector
                        history.push(`/companies-by-sector/${clickedSector}`);
                      }
                    },
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: 'Sectors',
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: 'Number of Companies',
                        },
                        beginAtZero: true,
                        max: 15,
                      },
                    },
                  }}
                />
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="sector">
              <h1 style={{ margin: 'auto' }}>Companies in each Phase</h1>
              <div style={{ width: '50%', height: '50%', margin: 'auto' }}>
                {Object.keys(phaseData).length > 0 && (
                  <Pie
                    data={phaseData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'top',
                        },
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              const phaseName = context.label;
                              const companyCount = context.raw;
                              return `${phaseName}: ${companyCount} companies`;
                            },
                          },
                        },
                      },
                    }}
                  />
                )}

                <div style={{ marginTop: '20px' }}>
                  <h3>Filter by Phase:</h3>
                  <div>
                    {groupedByPhase &&
                      Object.keys(groupedByPhase).map((phase, idx) => (
                        <button
                          key={phase}
                          onClick={() => handleFilter(phase, idx)}
                          style={{
                            margin: '5px',
                            padding: '10px',
                            backgroundColor: 'rgba(75, 192, 192, 0.6)',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                          }}
                        >
                          Phase {phase}
                        </button>
                      ))}
                  </div>
                </div>
                {filteredPhase && (
                  <div style={{ marginTop: '20px' }}>
                    <h4>Companies in Phase {filteredPhase}:</h4>
                    <ul>
                      {groupedByPhase[filteredPhase].map((company, index) => (
                        <li key={index}>{company}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <CompanyStats2 index={idx} />
        </Container>
      </main>
    </div>
  );
}

export default CompanyStats;
