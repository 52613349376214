import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import instance from '../../api/axios';
import {
  Container,
  CssBaseline,
  makeStyles,
  Paper,
  Grid,
  Typography,
} from '@material-ui/core';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ArcElement
);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f4f6f8',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '-200px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4), // Increased margin for better spacing
    textAlign: 'center',
    color: theme.palette.primary.main, // Use primary color for the title
  },
  card: {
    backgroundColor: '#fff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    padding: theme.spacing(3),
    minHeight: '300px', // Increased height for better chart visibility
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.2s ease-in-out', // Add hover effect
    '&:hover': {
      transform: 'scale(1.02)', // Slight scale on hover
    },
  },
  cardTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(3), // Increased margin for better spacing
    textAlign: 'center',
    color: theme.palette.secondary.main, // Use secondary color for card titles
  },
  gridContainer: {
    marginTop: theme.spacing(4), // Increased margin for better spacing
    width: '100%', // Ensure grid takes full width
  },
  chartContainer: {
    width: '100%', // Ensure charts take full width of the card
    height: '100%', // Ensure charts take full height of the card
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
}));
const CompanyStats = ({ index }) => {
  const [data, setData] = useState(null);
  const classes = useStyles();
  const selectedPhase = `Phase-${index + 1}`;
  console.log(selectedPhase);

  useEffect(() => {
    instance
      .get(
        'placement_statistics/company_details/?expand=true&include_roles=true'
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // if (!index) return <div>select a phase</div>;
  if (!data) return <div>Loading...</div>;

  const filteredData = data.filter((company) =>
    company.phases.some((phase) => phase.phase === selectedPhase)
  );

  // Process data for various charts with filtered data
  const companyStats = filteredData.map((company) => ({
    name: company.company,
    students: company.phases
      .filter((phase) => phase.phase === selectedPhase)
      .reduce(
        (acc, phase) =>
          acc +
          phase.sectors.reduce(
            (acc2, sector) =>
              acc2 +
              sector.roles.reduce(
                (acc3, role) => acc3 + role.students.length,
                0
              ),
            0
          ),
        0
      ),
    avgCTC:
      company.phases
        .filter((phase) => phase.phase === selectedPhase)
        .reduce(
          (acc, phase) =>
            acc +
            phase.sectors.reduce(
              (acc2, sector) =>
                acc2 + sector.roles.reduce((acc3, role) => acc3 + role.ctc, 0),
              0
            ),
          0
        ) /
      (company.phases.filter((phase) => phase.phase === selectedPhase).length ||
        1),
  }));

  const genderData = filteredData.reduce((acc, company) => {
    company.phases
      .filter((phase) => phase.phase === selectedPhase)
      .forEach((phase) => {
        phase.sectors.forEach((sector) => {
          sector.roles.forEach((role) => {
            role.students.forEach((student) => {
              acc[student.gender] = (acc[student.gender] || 0) + 1;
            });
          });
        });
      });
    return acc;
  }, {});

  let genderChartData = Object.entries(genderData).map(([gender, count]) => ({
    label: gender,
    value: count,
  }));
  genderChartData = {
    labels: genderChartData.map((item) => item.label),
    datasets: [
      {
        data: genderChartData.map((item) => item.value),
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)', // Pink for Female
          'rgba(54, 162, 235, 0.8)', // Blue for Male
        ],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const optionsForGender = {
    responsive: true,
    maintainAspectRatio: false, // This allows us to control dimensions manually
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 12,
          padding: 10,
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: false, // Removed title since you have Typography for that
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw || 0;
            const total = context.dataset.data.reduce(
              (acc, val) => acc + val,
              0
            );
            const percentage = Math.round((value / total) * 100);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  const cgpaRanges = {
    '6.0-7.0': 0,
    '7.0-8.0': 0,
    '8.0-9.0': 0,
    '9.0-10.0': 0,
  };

  filteredData.forEach((company) => {
    company.phases
      .filter((phase) => phase.phase === selectedPhase)
      .forEach((phase) => {
        phase.sectors.forEach((sector) => {
          sector.roles.forEach((role) => {
            role.students.forEach((student) => {
              if (student.cgpa >= 6.0 && student.cgpa < 7.0)
                cgpaRanges['6.0-7.0']++;
              else if (student.cgpa >= 7.0 && student.cgpa < 8.0)
                cgpaRanges['7.0-8.0']++;
              else if (student.cgpa >= 8.0 && student.cgpa < 9.0)
                cgpaRanges['8.0-9.0']++;
              else if (student.cgpa >= 9.0 && student.cgpa <= 10.0)
                cgpaRanges['9.0-10.0']++;
            });
          });
        });
      });
  });

  // Chart.js data for the company statistics
  const barChartData = {
    labels: companyStats.map((stat) => stat.name),
    datasets: [
      {
        label: 'Number of Students',
        data: companyStats.map((stat) => stat.students),
        backgroundColor: '#8884d8',
        borderColor: '#8884d8',
        borderWidth: 1,
      },
    ],
  };

  // Chart.js data for the average CTC
  const avgCTCChartData = {
    labels: companyStats.map((stat) => stat.name),
    datasets: [
      {
        label: 'Average CTC (LPA)',
        data: companyStats.map((stat) => stat.avgCTC),
        backgroundColor: '#82ca9d',
        borderColor: '#82ca9d',
        borderWidth: 1,
      },
    ],
  };

  return (
    <Container className={classes.root}>
      <CssBaseline />
      <Typography className={classes.title}>
        {`Placement Statistics of Phase-${index + 1}`}
      </Typography>

      <div className={classes.container}>
        {/* Company-wise Placements */}
        <Grid item xs={12} md={6}>
          <Paper className={classes.card}>
            <Typography className={classes.cardTitle}>
              Company-wise Placements
            </Typography>
            <Bar
              data={barChartData}
              options={{
                responsive: true,
                scales: {
                  y: {
                    ticks: {
                      // Use a callback to ensure the y-axis labels are integers
                      callback: function (value) {
                        return Number.isInteger(value)
                          ? value
                          : value.toFixed(0); // Show integer values
                      },
                    },
                  },
                },
              }}
            />
          </Paper>
        </Grid>
        {/* Average CTC by Company */}
        <Grid item xs={12} md={6}>
          <Paper className={classes.card}>
            <Typography className={classes.cardTitle}>
              Average CTC by Company (LPA)
            </Typography>
            <Bar data={avgCTCChartData} options={{ responsive: true }} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.card}>
            <Typography className={classes.cardTitle}>
              Gender wise Distribution (number of students)
            </Typography>
            <div
              className="chart-container"
              style={{
                position: 'relative',
                height: '200px',
                width: '100%',
                maxWidth: '300px',
                margin: '0 auto',
              }}
            >
              <Pie data={genderChartData} options={optionsForGender} />
            </div>
          </Paper>
        </Grid>
      </div>
    </Container>
  );
};

export default CompanyStats;
